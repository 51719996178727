

import AbstractComponentVue from '../services/AbstractComponentVue';
import {Component, Watch} from 'vue-property-decorator';
import {services} from '@/main';
import {EnumUserServiceType, IMailServiceRequest, IUserServicePrice} from '@/services/dto/IDtos';
import UserCard from '@/components/UserCard.vue';
import {IUserResponse} from '@/services/dto/IUserResponse';
import {IMailService, IUserService} from '@/services/dto/IUserService';
import UserUtils from '@/utils/UserUtils';
import AlertService from '../utils/AlertService';
import SigIconServiceState from '@/components/user/SigIconServiceState.vue';
import UserServicesUtils from '@/services/UserServicesUtils';
import DisclaimerVisio from '@/components/ficheBilan/DisclaimerVisio.vue';

@Component({
  components: {
    DisclaimerVisio,
    SigIconServiceState,
    UserCard,
  },
})
export default class UserDetail extends AbstractComponentVue {

  private id: number = this.$route.params.id as unknown as number;

  private uriMailReactivate: string = '/account/' + this.$route.params.id + '/services/MAIL/reactivate';
  private uriMailDeactivate: string = '/account/' + this.$route.params.id + '/services/MAIL/deactivate';
  private uriMailActivate: string = '/account/' + this.$route.params.id + '/services/MAIL/activate';
  private urlTransfertMail: string = '/api/v1/user-services/mail/' + this.$route.params.id + '/get-mail-transfert-form-document/';
  private uriBalladeDeactivate: string = '/account/' + this.$route.params.id + '/services/BALLADE/deactivate';
  private uriBalladeActivate: string = '/account/' + this.$route.params.id + '/services/BALLADE/activate';
  private uriVisioDeactivate: string = '/account/' + this.$route.params.id + '/services/VISIO/deactivate';
  private uriVisioActivate: string = '/account/' + this.$route.params.id + '/services/VISIO/activate';

  private mailPrice: IUserServicePrice = {} as IUserServicePrice;
  private balladePrice: IUserServicePrice = {} as IUserServicePrice;
  private visioPrice: IUserServicePrice = {} as IUserServicePrice;

  private frontService: IUserService = {} as IUserService;
  private mailService: IMailService = {} as IUserService;
  private balladeService: IUserService = {} as IUserService;
  private visioService: IUserService = {} as IUserService;
  private isNotEntity: boolean = false;

  // Si on affiche la souscription ballade
  private displayBallade: boolean = true;

  // Si on affiche la souscription visioSoft
  private displayVisioSoftButton: boolean = true;
  private userCardMounted: boolean = true;
  private mailServiceExterne: boolean = false;

  /**
   * Recharge les info de la page quand l'URL change (l'identifiant).
   */
  @Watch('$route', {immediate: true, deep: true})
  private onUrlChange(newVal: any) {
    this.id = this.$route.params.id as unknown as number;
    this.loadUser(this.id);

    // Décharge et recharge le composant UserCard.
    this.userCardMounted = false;
    setInterval(() => {
      this.userCardMounted = true;
    }, 100);
  }


  private mounted() {
    // Récupération des prix de chaque service
    services.restService
    .callGet('/api/v1/user-services/MAIL/price')
    .then((response: any) => {
			this.mailPrice = response.data;
		});
    services.restService
    .callGet('/api/v1/user-services/BALLADE/price')
    .then((response: any) => {
			this.balladePrice = response.data;
		});
    services.restService
    .callGet('/api/v1/user-services/VISIO_SOFT/price')
    .then((response: any) => {
			this.visioPrice = response.data;
    });
  }

  /**
   * Ouvre le popup de modifications d'options.
   */
  private showMailEdition(): void {
    this.showModal('edit-mail');
  }

  /**
   * Annule les modifications.
   */
  private cancelMailOption(): void {
    this.loadUser (this.id);
    this.hideModal('edit-mail');
  }

  /**
   * Enregistre le choix de l'utilisateur
   */
  private saveMailOptions() {
    const uri: string = '/api/v1/user-services/mail/' + this.id + '/options';
    const mailServiceRequest: IMailServiceRequest = {} as IMailServiceRequest;
    mailServiceRequest.accountId = this.id;
    mailServiceRequest.webmailActivated = this.mailService.activeWebmail as boolean;
    mailServiceRequest.sslActivated = this.mailService.activeSmartphone as boolean;
    const promise: Promise<any> = services.restService.callPatch(uri, mailServiceRequest);
    promise.then((res) => {
      if (typeof res !== 'undefined' && res.status === 200) {
        AlertService.success('Votre demande a bien été prise en compte.');
      }
      this.hideModal('edit-mail');
      this.loadUser (this.id);
    });
  }

  /**
   * Création de la page.
   */
  private created() {
    const id: number = this.$route.params.id as unknown as number;
    this.loadUser (id);
  }

  /**
   * Charge les info de l'utilisateur
   */
  private loadUser(id: number): void {
    services.restService.callGet('/api/v1/accounts/' + id)
      .then((res) => {
        const userResponse: IUserResponse = res.data;
        this.mailService = {} as IUserService;
        this.displayBallade = res.data.displayBallade;
        this.displayVisioSoftButton = res.data.displayVisioSoftButton;
        this.mailServiceExterne = userResponse.mailServiceExterne;
        this.frontService = {} as IUserService;
        this.balladeService = {} as IUserService;
        this.visioService = {} as IUserService;
        this.isNotEntity = !UserUtils.isEntity(userResponse);

        this.frontService = UserServicesUtils.findUserServiceByType(userResponse, EnumUserServiceType.MER);
        this.mailService = UserServicesUtils.findUserServiceByType(userResponse, EnumUserServiceType.MAIL);
        this.balladeService = UserServicesUtils.findUserServiceByType(userResponse, EnumUserServiceType.BALLADE);
        this.visioService = UserServicesUtils.findUserServiceByType(userResponse, EnumUserServiceType.VISIO_SOFT);

      });

  }
}
